'use client'

import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { useTheme } from 'styled-components';

import constants from '../../../../sites/constants';
import {
  selectAddingStatus,
  selectCurrentLineNumber,
  updateAddingStatus,
  updateCurrentLineNumber,
} from '../../../features/bag';
import { selectCurrencyData, selectShowBrandNameProductCard, selectToggles } from '../../../features/static';
import { GET_PRODUCT_MEDIA } from '../../../graphQl/queries/productMedia';
import { GET_PRODUCT_SIZE_AVAILABILITY } from '../../../graphQl/queries/productSizeAvailability';
import { QuickBuySize } from '../../../types/custom-types';
import {
  ProductImage,
  ProductListItem,
  ProductMedia,
  ProductSize,
} from '../../../types/middleware-types';
import { AddingStatus } from '../../../types/state-types';
import { isNode, isSize } from '../../../types/type-checkers';
import useAddToBag from '../../../utils/customHooks/useAddToBag';
import useMobileWatcher from '../../../utils/customHooks/useMobileWatcher';
import addToBasketDataLayer from '../../../utils/DataLayer/formatAddToBasket';
import { getPricePercentOff } from '../../../utils/getters/getPricePercentOff';
import { ProductForDataLayer } from './ProductCard.types';
import ProductCardStructure from './ProductCardStructure';

export interface ProductCardProps extends Omit<ProductListItem, 'media'> {
  pageName: string | undefined;
  componentName?: string;
  images: ProductMedia;
  showQuickBuy: boolean;
  addProductPositionToLocalStorage: (productPosition: number, lineNumber: string) => void;
  productIndex?: number;
  productView?: { productViewSetting: string };
  lazyload?: boolean;
  imgPriority?: boolean;
  isNewInPLP: boolean | null;
  imgSizes?: string;
}

const ProductCard: FC<ProductCardProps> = ({
  pageName,
  componentName,
  imageRoundel,
  images,
  price,
  brand,
  name,
  url,
  urlKey,
  sale,
  offer,
  exclusive,
  clearance,
  priceDrop,
  newIn,
  showQuickBuy,
  lineNumber,
  brandCode,
  colour,
  addProductPositionToLocalStorage,
  productIndex,
  productView,
  lazyload,
  imgPriority,
  isNewInPLP,
  imgSizes,
}) => {
  const toggles = useSelector(selectToggles);
  const currencyData = useSelector(selectCurrencyData);
  const showBrandNameProductCard = useSelector(selectShowBrandNameProductCard);
  const { datalayerAddToBasketSizesType } = constants;
  // Deprecate this when toggle is ready to be removed.
  const [rolloverImage, setRolloverImage] = useState('');
  const [showRolloverImage, setShowRolloverImage] = useState(false);
  const [sizes, setSizes] = useState<QuickBuySize[] | null>(null);
  const [openQuickBuy, setOpenQuickBuy] = useState(false);
  const [sizeType, setSizeType] = useState('uk');
  const [selectedSize, setSelectedSize] = useState('');
  const [productMedia, setProductMedia] = useState<{ images: ProductImage[] } | null>(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const { featureKgRedesign, engs13899AdlRoundelPlp, engs14345ReactRoutingATagsToLinks } = toggles;
  const [loadExtraFeatures, setLoadExtraFeatures] = useState(false);
  const [addStyleForShowingQuickBuy, setAddStyleForShowingQuickBuy] = useState(false);

  const [showSliderArrows, setShowSliderArrows] = useState(false);

  const currentLineNumber = useSelector(selectCurrentLineNumber);
  const addingStatus = useSelector(selectAddingStatus);
  const bagDispatch = useDispatch();
  const [productSizeAvailability, setProductSizeAvailability] = useState<ProductSize[]>([]);
  const { addToBag: addProductToBag } = useAddToBag();
  const theme = useTheme();
  const { isDesktop } = useMobileWatcher(['desktop'], theme.vars);
  const quickBuyWrapperRef = useRef<HTMLDivElement>(null);
  const [focusQuickBuy, setFocusQuickBuy] = useState(false);
  const { productViewSetting } = productView ?? { productViewSetting: 'default' };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isNode(event.target)) {
        if (quickBuyWrapperRef.current && !quickBuyWrapperRef?.current?.contains(event.target)) {
          setFocusQuickBuy(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [quickBuyWrapperRef]);

  const imageData = images?.productImages;
  const [getProductSizeAvailability, { data, loading }] = useLazyQuery(
    GET_PRODUCT_SIZE_AVAILABILITY,
  );
  const [getProductMediaData, { data: productMediaData }] = useLazyQuery(GET_PRODUCT_MEDIA);

  const updateCarouselIndex = (index: number) => {
    setCarouselIndex(index);
  };

  const selectSize = (sku: string) => {
    setSelectedSize(sku);
  };

  const handleGetProductMedia = () => {
    if (!productMedia) {
      getProductMediaData({ variables: { lineNumber } });
    }
  };

  const handleRollOverImage = (rolloverImageUrl: string) => {
    if (carouselIndex === 0 && showQuickBuy) {
      setRolloverImage(rolloverImageUrl);
      setShowRolloverImage(true);
    } else {
      setRolloverImage('');
      setShowRolloverImage(false);
    }

    if (rolloverImageUrl === '') {
      setShowRolloverImage(false);
    }
  };

  const handleContentMouseEnter = (rolloverImageUrl: string) => {
    handleRollOverImage(rolloverImageUrl);
    setLoadExtraFeatures(true);
    setShowSliderArrows(true);

    setTimeout(() => {
      setAddStyleForShowingQuickBuy(true);
    }, 0);
  };

  const handleContentMouseLeave = (rolloverImageUrl: string) => {
    handleRollOverImage(rolloverImageUrl);
    setShowSliderArrows(false);
  };

  const productObjectForDatalayer: ProductForDataLayer = {
    name,
    brand,
    brandCode,
    price,
    priceDrop,
    sale,
    sizes: productSizeAvailability,
    lineNumber,
    newIn,
    media: images,
    colour,
    clearance,
    exclusive,
    offer,
    url,
    urlKey,
  };

  /**
   * add to bag handler for quick buy
   */
  const addToBag = async () => {
    const finalPageName = pageName || 'page not known'
    const selectedSizeObj = productSizeAvailability.find((s) => s.sku === selectedSize);
    const size =
      selectedSizeObj?.labels.original !== 'NOSZ'
        ? `${selectedSizeObj?.labels.uk} / ${selectedSizeObj?.labels.eu}`
        : 'NO SIZE';
    addToBasketDataLayer(
      productObjectForDatalayer,
      selectedSize,
      size,
      finalPageName,
      datalayerAddToBasketSizesType,
      currencyData
    );
    await addProductToBag({ quantity: 1, sku: selectedSize, lineNumber }, price.now, url);
  };

  const flags = {
    newIn,
    sale,
    offer,
    exclusive,
    clearance,
    priceDrop,
  };

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeigerus' || process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeigermexico') {
      setSizeType('us');
    }
    if (process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID === 'kurtgeigerglobal') {
      setSizeType('eu');
    }
  }, []);

  useEffect(() => {
    if (productSizeAvailability.length > 0) {
      const productSizes = productSizeAvailability.map((size) => ({
        sku: size.sku,
        stockMessage: size.stockMessage,
        inStock: size.inStock,
        label:
          size.labels.original !== 'NOSZ'
            ? size.labels[isSize(sizeType) ? sizeType : 'uk']
            : undefined,
      }));
      setSizes(productSizes);
      if (productSizes.length === 1) {
        setSelectedSize(productSizes[0].sku);
      }
    }
  }, [productSizeAvailability, sizeType]);

  useEffect(() => {
    if (data) {
      setProductSizeAvailability(data.productSizeAvailability);
    }
  }, [data]);

  // get product sizes when quick buy button is clicked
  const toggleQuickBuy = (selectedLineNumber: string): void => {
    if (selectedLineNumber?.length > 0 && selectedLineNumber !== currentLineNumber) {
      getProductSizeAvailability({ variables: { lineNumber: selectedLineNumber } });
      if (addingStatus === AddingStatus.Added) {
        bagDispatch(updateAddingStatus(null));
      }
    }
    bagDispatch(updateCurrentLineNumber(selectedLineNumber));
  };

  useEffect(() => {
    setOpenQuickBuy(currentLineNumber === lineNumber);
  }, [currentLineNumber]);

  useEffect(() => {
    if (!productMedia && productMediaData?.productMedia) {
      setProductMedia(productMediaData.productMedia);
    }
  }, [productMediaData]);

  const imageRoundelAlt = imageRoundel !== null ? imageRoundel?.replace(/_/g, ' ') : '';

  // Push to local url if not in productions for pdp. Will get 404 otherwise as route not valid
  const lastPartOfUrl = url?.substring(url?.lastIndexOf('/') + 1);
  const localUrl = `/pdp/${lastPartOfUrl}`;
  const finalUrl = process.env.NODE_ENV !== 'production' && !engs14345ReactRoutingATagsToLinks ? localUrl : url

  const showPercentOff = pageName?.includes('plp')
    ? toggles?.featurePricePercentOffMessagePlp
    : toggles?.featurePricePercentOffMessageProductCards;

  const isADLRoundel = imageRoundel !== null && imageRoundel !== undefined && (imageRoundel.toUpperCase() === 'CARVELAROUNDEL');
  const hideRoundel = engs13899AdlRoundelPlp && isADLRoundel;

  const componentProps = {
    pageName,
    componentName,
    imageRoundel,
    imageRoundelAlt,
    images: imageData ? { images: imageData } : null,
    now: price?.now,
    nowFormatted: price?.nowFormatted,
    was: price?.was,
    wasFormatted: price?.wasFormatted,
    percentOff: !toggles.featureHideWasPrice && showPercentOff ? getPricePercentOff(price) : null,
    brand,
    name,
    url: finalUrl,
    urlKey,
    priceDrop,
    rolloverImage,
    showRolloverImage,
    sizes,
    selectedSize,
    selectSize,
    addToBag,
    flags,
    isNewInPLP,
    handleRollOverImage,
    showQuickBuy: showQuickBuy && loadExtraFeatures,
    lineNumber,
    toggleQuickBuy,
    openQuickBuy,
    showBrandName: showBrandNameProductCard,
    addProductPositionToLocalStorage,
    productIndex,
    theme,
    sizeType,
    setSizeType,
    loading,
    handleGetProductMedia,
    productView,
    updateCarouselIndex,
    isDesktop,
    lazyload,
    colour: colour ? colour.toLowerCase() : 'no colour',
    focusQuickBuy,
    setFocusQuickBuy,
    quickBuyWrapperRef,
    handleContentMouseEnter,
    handleContentMouseLeave,
    loadExtraFeatures,
    showSliderArrows,
    addStyleForShowingQuickBuy,
    imgPriority,
    // @ts-ignore
    imgSizes: imgSizes ?? constants.plpProductCardSizes[productViewSetting],
    currencySymbol: currencyData?.symbol,
    featureKgRedesign,
    hideRoundel,
    useReactRoutingLinks: engs14345ReactRoutingATagsToLinks,
    engs15248PlpRolloverImageChanges: toggles?.engs15248PlpRolloverImageChanges,
    showCheckoutQuickBuy: pageName === 'Cart',
    quickBuyBtnLabel: 'Quick Buy',
  };

  return <ProductCardStructure {...componentProps} />;
};

export default memo(ProductCard);