import React, { Dispatch, FC, memo, Ref, SetStateAction } from 'react';
import Link from 'next/link';
import { DefaultTheme } from 'styled-components';

import NextImage from '../../../basic/NextImage';
import { QuickBuySize, SalesFlagType } from '../../../types/custom-types';
import { Maybe, ProductImageInfo, ProductListItem } from '../../../types/middleware-types';
import { createMediaList } from '../../../utils/helpers/createMediaList';
import { normaliseImageSrc } from '../../../utils/helpers/imageLoader';
import Loading from '../../Loading';
import Price from '../../Price';
import QuickBuy from '../../QuickBuy';
import SalesFlag from '../../SalesFlag';
import ScreenReaderOnlyText from '../../ScreenReaderOnlyText';
import Slider from '../../Slider';
import {
  StyledBrand,
  StyledCarousel,
  StyledCarouselContainer,
  StyledContent,
  StyledContentContainer,
  StyledImageContainer,
  StyledProductCardContainer,
  StyledProductCardContainerTop,
  StyledProductName,
  StyledQuickBuyButton,
  StyledQuickBuyContainer,
  StyledQuickBuyWrapper,
  StyledRolloverContainer,
  StyledRoundel,
} from './ProductCardStyles';
import settings from './settings';

interface ProductCardStructureProps
  extends Omit<
    ProductListItem,
    'media' | 'newIn' | 'sale' | 'offer' | 'price' | 'exclusive' | 'clearance'
  > {
  pageName: string | undefined;
  componentName?: string;
  imageRoundelAlt?: string;
  images: { images: ProductImageInfo[] } | null;
  now: string;
  nowFormatted: string;
  was?: Maybe<string>;
  wasFormatted?: Maybe<string>;
  rolloverImage: string;
  showRolloverImage: boolean;
  handleRollOverImage: (rolloverImage: string) => void;
  sizes: QuickBuySize[] | null;
  selectedSize: string;
  selectSize: (sku: string) => void;
  flags: {
    [key in SalesFlagType]: boolean;
  };
  isNewInPLP: boolean | null;
  addToBag: () => Promise<void>;
  updateCarouselIndex: (index: number) => void;
  showQuickBuy: boolean;
  theme: DefaultTheme;
  toggleQuickBuy: (selectedLineNumber: string) => void;
  openQuickBuy: boolean;
  showBrandName: boolean;
  addProductPositionToLocalStorage: (productPosition: number, lineNumber: string) => void;
  productIndex?: number;
  sizeType: string;
  setSizeType: Dispatch<SetStateAction<string>>;
  loading: boolean;
  handleGetProductMedia: () => void;
  isDesktop: boolean | undefined;
  productView?: { productViewSetting: string };
  focusQuickBuy: boolean;
  setFocusQuickBuy: Dispatch<SetStateAction<boolean>>;
  quickBuyWrapperRef: Ref<HTMLDivElement>;
  handleContentMouseEnter: (rolloverImage: string) => void;
  handleContentMouseLeave: (rolloverImage: string) => void;
  showSliderArrows: boolean;
  loadExtraFeatures?: boolean;
  addStyleForShowingQuickBuy?: boolean;
  imgPriority?: boolean;
  percentOff: number | null;
  imgSizes: string;
  currencySymbol: string;
  featureKgRedesign: boolean;
  hideRoundel: boolean;
  useReactRoutingLinks: boolean;
  showCheckoutQuickBuy?: boolean
  quickBuyBtnLabel: string;
  engs15248PlpRolloverImageChanges?: boolean;
}

const ProductCardStructure: FC<ProductCardStructureProps> = ({
  pageName,
  componentName,
  imageRoundel,
  imageRoundelAlt,
  images,
  was,
  wasFormatted,
  now,
  nowFormatted,
  percentOff,
  brand,
  name,
  url,
  rolloverImage,
  showRolloverImage,
  handleRollOverImage,
  sizes,
  selectedSize,
  selectSize,
  flags,
  isNewInPLP,
  addToBag,
  updateCarouselIndex,
  showQuickBuy,
  theme,
  lineNumber,
  toggleQuickBuy,
  openQuickBuy,
  showBrandName,
  addProductPositionToLocalStorage,
  productIndex,
  sizeType,
  setSizeType,
  loading,
  handleGetProductMedia,
  isDesktop,
  productView,
  colour,
  focusQuickBuy,
  setFocusQuickBuy,
  quickBuyWrapperRef,
  handleContentMouseEnter,
  handleContentMouseLeave,
  showSliderArrows,
  loadExtraFeatures,
  addStyleForShowingQuickBuy,
  imgPriority,
  imgSizes,
  currencySymbol,
  featureKgRedesign,
  hideRoundel,
  useReactRoutingLinks,
  showCheckoutQuickBuy,
  quickBuyBtnLabel,
  engs15248PlpRolloverImageChanges,
}) => {
  return (
    <StyledProductCardContainerTop
      $showQuickBuy={showQuickBuy}
      $addStyleForShowingQuickBuy={addStyleForShowingQuickBuy}
      onFocus={() => setFocusQuickBuy(true)}
      onBlur={() => setFocusQuickBuy(false)}
      data-hookid={`${pageName}${componentName}ProductCardTop`}
    >
      {useReactRoutingLinks ? (
        <Link href={url} passHref legacyBehavior>
          <StyledProductCardContainer
            $quickAdd={openQuickBuy}
            $focusQuickBuy={focusQuickBuy}
            $showCheckoutQuickBuy={showCheckoutQuickBuy}
            data-hookid={`${pageName}${componentName}ProductCard`}
            onClick={() => {
              addProductPositionToLocalStorage((productIndex ?? 1) + 1, lineNumber);
            }}
          >
            <StyledContentContainer
              data-hookid={`${pageName}ProductCardContainer`}
              onClick={() => handleRollOverImage('')}
              {...(isDesktop
                ? {
                  onMouseEnter: () => handleContentMouseEnter(images?.images[1]?.url ?? ''),
                  onMouseLeave: () => handleContentMouseLeave(''),
                }
                : {})}
            >
              {images && (
                <>
                  {imageRoundel && !hideRoundel && imageRoundel.toLowerCase() !== 'none' && (
                    <StyledRoundel $productView={productView?.productViewSetting}>
                      <NextImage
                        dataHookId={`${pageName}ImageRoundel`}
                        mediaList={createMediaList({
                          src: `${theme.vars.imageBaseUrl}/roundels/${imageRoundel}.png`,
                        })}
                        alt={imageRoundelAlt}
                        imageType="fill"
                        sizes="(min-width: 1024px) 25vw, 50vw"
                      />
                    </StyledRoundel>
                  )}
                  <StyledCarousel
                    $quickAdd={showQuickBuy}
                    aria-hidden
                    data-hookid={`${pageName}ProductCardCarousel`}
                  >
                    {showQuickBuy && isDesktop && loadExtraFeatures ? (
                      <StyledCarouselContainer>
                        <Slider
                          {...settings}
                          arrows={showSliderArrows}
                          afterChange={(index) => updateCarouselIndex(index)}
                          beforeChange={() => handleGetProductMedia()}
                        >
                          {images?.images?.length > 0 &&
                            images.images.map((_, imgIndex) => {
                              const productImageInfoType = images?.images[imgIndex];
                              return (
                                <div
                                  data-hookid="ImageContainer"
                                  key={images?.images[0]?.url}
                                  onBlur={() => handleRollOverImage(images?.images[1]?.url ?? '')}
                                  onFocus={() => handleRollOverImage(images?.images[1]?.url ?? '')}
                                  {...(isDesktop
                                    ? {
                                      onMouseEnter: () =>
                                        handleRollOverImage(images?.images[1]?.url ?? ''),
                                      onMouseLeave: () => handleRollOverImage(''),
                                    }
                                    : {})}
                                >
                                  { engs15248PlpRolloverImageChanges ? (
                                    <StyledRolloverContainer $showRolloverImage={showRolloverImage}>
                                      <NextImage
                                        mediaList={createMediaList({
                                          src: normaliseImageSrc(productImageInfoType?.url)
                                        })}
                                        alt={`${name} - ${colour}`}
                                        imageType="fill"
                                        sizes={imgSizes}
                                        priority={imgPriority}
                                        className="default"
                                      />
                                      <NextImage
                                        mediaList={createMediaList({
                                          src: normaliseImageSrc(images?.images[1]?.url)
                                        })}
                                        alt={`${name} - ${colour}`}
                                        imageType="fill"
                                        sizes={imgSizes}
                                        priority={imgPriority}
                                        className="rollover"
                                      />
                                    </StyledRolloverContainer>
                                  ) : (
                                    <NextImage
                                      mediaList={createMediaList({
                                        src: normaliseImageSrc(rolloverImage || productImageInfoType?.url)
                                      })}
                                      alt={`${name} - ${colour}`}
                                      imageType="fill"
                                      sizes={imgSizes}
                                      priority={imgPriority}
                                    />
                                  )}
                                </div>
                              )
                            })}
                        </Slider>
                      </StyledCarouselContainer>
                    ) : (
                      <StyledImageContainer>
                        <div data-hookid="ImageContainer">
                          <NextImage
                            mediaList={createMediaList({
                              src: normaliseImageSrc(images?.images[0]?.url),
                            })}
                            alt={`${name} - ${colour}`}
                            imageType="fill"
                            sizes={imgSizes}
                            priority={imgPriority}
                          />
                        </div>
                      </StyledImageContainer>
                    )}
                  </StyledCarousel>
                  <ScreenReaderOnlyText
                    text={`Product: ${name}, Brand: ${brand}, Colour: ${colour}, Price: ${currencySymbol}${now} Line Number: ${lineNumber}.`}
                  />
                </>
              )}
              <SalesFlag
                flags={flags}
                isNewInPLP={isNewInPLP}
                percentOff={percentOff}
                lineNumber={lineNumber}
              />
            </StyledContentContainer>
            <StyledContent className="content" aria-hidden $featureKgRedesign={featureKgRedesign}>
              {showBrandName && <StyledBrand data-hookid={`${pageName}BrandName`}>{brand}</StyledBrand>}
              <StyledProductName $featureKgRedesign={featureKgRedesign}>{name}</StyledProductName>
              <Price was={was} wasFormatted={wasFormatted} sale={flags.sale} now={now} nowFormatted={nowFormatted} lineNumber={lineNumber} />
            </StyledContent>
          </StyledProductCardContainer>
        </Link>
      ) : (
        <StyledProductCardContainer
          $quickAdd={openQuickBuy}
          $focusQuickBuy={focusQuickBuy}
          data-hookid={`${pageName}${componentName}ProductCard`}
          onClick={() => {
            addProductPositionToLocalStorage((productIndex ?? 1) + 1, lineNumber);
          }}
          href={url}
          $showCheckoutQuickBuy={showCheckoutQuickBuy}
        >
          <StyledContentContainer
            data-hookid={`${pageName}ProductCardContainer`}
            onClick={() => handleRollOverImage('')}
            {...(isDesktop
              ? {
                onMouseEnter: () => handleContentMouseEnter(images?.images[1]?.url ?? ''),
                onMouseLeave: () => handleContentMouseLeave(''),
              }
              : {})}
          >
            {images && (
              <>
                {imageRoundel && !hideRoundel && imageRoundel.toLowerCase() !== 'none' && (
                  <StyledRoundel $productView={productView?.productViewSetting}>
                    <NextImage
                      dataHookId={`${pageName}ImageRoundel`}
                      mediaList={createMediaList({
                        src: `${theme.vars.imageBaseUrl}/roundels/${imageRoundel}.png`,
                      })}
                      alt={imageRoundelAlt}
                      imageType="fill"
                      sizes="(min-width: 1024px) 25vw, 50vw"
                    />
                  </StyledRoundel>
                )}
                <StyledCarousel
                  $quickAdd={showQuickBuy}
                  aria-hidden
                  data-hookid={`${pageName}ProductCardCarousel`}
                >
                  {showQuickBuy && isDesktop && loadExtraFeatures ? (
                    <StyledCarouselContainer>
                      <Slider
                        {...settings}
                        arrows={showSliderArrows}
                        afterChange={(index) => updateCarouselIndex(index)}
                        beforeChange={() => handleGetProductMedia()}
                      >
                        {images?.images?.length > 0 &&
                          images.images.map((_, imgIndex) => {
                            const productImageInfoType = images?.images[imgIndex];
                            return (
                              <div
                                data-hookid="ImageContainer"
                                key={images?.images[0]?.url}
                                onBlur={() => handleRollOverImage(images?.images[1]?.url ?? '')}
                                onFocus={() => handleRollOverImage(images?.images[1]?.url ?? '')}
                                {...(isDesktop
                                  ? {
                                    onMouseEnter: () =>
                                      handleRollOverImage(images?.images[1]?.url ?? ''),
                                    onMouseLeave: () => handleRollOverImage(''),
                                  }
                                  : {})}
                              >
                                { engs15248PlpRolloverImageChanges ? (
                                  <StyledRolloverContainer $showRolloverImage={showRolloverImage}>
                                    <NextImage
                                      mediaList={createMediaList({
                                        src: normaliseImageSrc(productImageInfoType?.url)
                                      })}
                                      alt={`${name} - ${colour}`}
                                      imageType="fill"
                                      sizes={imgSizes}
                                      priority={imgPriority}
                                      className="default"
                                    />
                                    <NextImage
                                      mediaList={createMediaList({
                                        src: normaliseImageSrc(images?.images[1]?.url)
                                      })}
                                      alt={`${name} - ${colour}`}
                                      imageType="fill"
                                      sizes={imgSizes}
                                      priority={imgPriority}
                                      className="rollover"
                                    />
                                  </StyledRolloverContainer>
                                ) : (
                                  <NextImage
                                    mediaList={createMediaList({
                                      src: normaliseImageSrc(rolloverImage || productImageInfoType?.url)
                                    })}
                                    alt={`${name} - ${colour}`}
                                    imageType="fill"
                                    sizes={imgSizes}
                                    priority={imgPriority}
                                  />
                                )}
                              </div>
                            )
                          })}
                      </Slider>
                    </StyledCarouselContainer>
                  ) : (
                    <StyledImageContainer>
                      <div data-hookid="ImageContainer">
                        <NextImage
                          mediaList={createMediaList({
                            src: normaliseImageSrc(images?.images[0]?.url),
                          })}
                          alt={`${name} - ${colour}`}
                          imageType="fill"
                          sizes={imgSizes}
                          priority={imgPriority}
                        />
                      </div>
                    </StyledImageContainer>
                  )}
                </StyledCarousel>
                <ScreenReaderOnlyText
                  text={`Product: ${name}, Brand: ${brand}, Colour: ${colour}, Price: ${currencySymbol}${now} Line Number: ${lineNumber}.`}
                />
              </>
            )}
            <SalesFlag
              flags={flags}
              isNewInPLP={isNewInPLP}
              percentOff={percentOff}
              lineNumber={lineNumber}
            />
          </StyledContentContainer>
          <StyledContent className="content" aria-hidden $featureKgRedesign={featureKgRedesign}>
            {showBrandName && <StyledBrand data-hookid={`${pageName}BrandName`}>{brand}</StyledBrand>}
            <StyledProductName $featureKgRedesign={featureKgRedesign}>{name}</StyledProductName>
            <Price was={was} wasFormatted={wasFormatted} sale={flags.sale} now={now} nowFormatted={nowFormatted} lineNumber={lineNumber} />
          </StyledContent>
        </StyledProductCardContainer>
      )}
      <>
        {(showCheckoutQuickBuy || showQuickBuy) && (
          <StyledQuickBuyWrapper
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            role="presentation"
            $focused={focusQuickBuy}
            $showQuickBuy={showQuickBuy}
            onFocus={() => setFocusQuickBuy(true)}
            onBlur={() => setFocusQuickBuy(false)}
            ref={quickBuyWrapperRef}
            data-hookid={`${pageName}QuickBuyWrapper`}
            $featureKgRedesign={featureKgRedesign}
            $showCheckoutQuickBuy={showCheckoutQuickBuy}
          >
            {openQuickBuy && sizes !== null ? (
              <QuickBuy
                addToBag={addToBag}
                sizes={sizes}
                closeQuickAdd={() => toggleQuickBuy('')}
                setSelectedSize={selectSize}
                selectedSize={selectedSize}
                sizeType={sizeType}
                setSizeType={setSizeType}
              />
            ) : (
              <StyledQuickBuyContainer data-hookid={`${pageName}QuickBuyAddToBag`}>
                <StyledQuickBuyButton
                  onClick={() => toggleQuickBuy(lineNumber)}
                  type="button"
                  $btnStylePrimary={showCheckoutQuickBuy}
                  data-hookid={`${pageName}QuickBuyAddToBagBtn`}
                >
                  {loading ? <Loading /> : quickBuyBtnLabel}
                </StyledQuickBuyButton>
              </StyledQuickBuyContainer>
            )}
          </StyledQuickBuyWrapper>
        )}
      </>
    </StyledProductCardContainerTop>
  );
};

export default memo(ProductCardStructure);